// ** React Imports
import { useContext, useMemo } from "react";

// ** Redux & Store & Actions
import { connect, useDispatch } from "react-redux";
import { setLanguage } from "store/modules/global";

// ** Custom Components
import Text from "components/typography/Text";
import Dropdown from "components/layout/Dropdown";
import MenuList from "components/layout/Menu/List";
import MenuItem from "components/layout/Menu/Item";
import { DropdownTriggerContext } from "components/layout/Dropdown/DropdownTriggerContext";

// ** Custom Hooks
import useOpenClose from "hooks/useOpenClose";

// ** Utils & Helpers
import { LANG } from "helpers/constants";

// ** Contexts
import authContext from "contexts/authContext";
import deviceTypeContext from "contexts/deviceTypeContext";

// ** Icons Imports
import CaretUp from "components/icons/CaretUp";
import CaretDown from "components/icons/CaretDown";

// ** Styled Components
import { borderRadiuses } from "styles/declares";

// #####################################################

const LangSelector = ({ language, parentIsFooter = false, label }) => {
	const dispatch = useDispatch();
	const isAuth = useContext(authContext);
	const { isPhone } = useContext(deviceTypeContext);
	const { isOpen, close, toggle } = useOpenClose();

	const extendedClose = ({ insideUseEffect = false } = {}) => {
		if (insideUseEffect) {
			close();
		} else {
			close(() => {
				if (
					parentIsFooter &&
					document.body.style.overflow === "hidden"
				) {
					document.body.style.overflow = "unset";
				}
			});
		}
	};

	const extendedToggle = () => {
		toggle(() => {
			if (
				!isOpen &&
				isPhone &&
				parentIsFooter &&
				document.body.style.overflow !== "hidden"
			) {
				document.body.style.overflow = "hidden";
			} else if (
				isOpen &&
				parentIsFooter &&
				document.body.style.overflow === "hidden"
			) {
				document.body.style.overflow = "unset";
			}
		});
	};

	const useLayerOptions = {
		overflowContainer: true,
		auto: true,
		possiblePlacements: ["bottom-start", "top-start"],
		triggerOffset: 5,
	};

	const customLayerStyle = {
		zIndex: 1000,
		borderRadius: `${borderRadiuses.card}rem`,
		borderTopRightRadius: `${borderRadiuses.card}rem`,
		borderTopLeftRadius: `${borderRadiuses.card}rem`,
	};

	const displayText = useMemo(() => {
		const languageIndex = LANG.findIndex(
			(languageItem) => languageItem.value === language
		);

		return languageIndex >= 0
			? LANG[languageIndex].content
			: LANG[1].content;
	}, [language]);

	// #####################################################

	return (
		<Dropdown
			isOpen={isOpen}
			close={(insideUseEffect) => {
				extendedClose(insideUseEffect);
			}}
			useLayerOptions={useLayerOptions}
			customLayerStyle={customLayerStyle}
			withMobileVersion={true}
			mobileTitle={label}
			customMobileLayerStyle={{
				left: parentIsFooter ? "1rem" : "2rem",
				right: parentIsFooter ? "1rem" : "2rem",
			}}
			isSingleDropdown={parentIsFooter}
			minWidth
			menuContent={() => (
				<MenuList isSelector>
					{LANG.map((item) => {
						return (
							<MenuItem
								tabIndex={0}
								onClick={() => {
									dispatch(
										setLanguage({
											language: item.value,
											auth: isAuth,
											setCookie: true,
										})
									);
									extendedClose();
								}}
								cliclable
								key={item.value || item.key}
								noMarginTop
							>
								<MenuItem.Content $customPadding="0.5rem 1rem">
									<Text
										size={"0.9rem"}
										bold={item.value === language}
									>
										{typeof item.content === "function"
											? item.content()
											: item.content}
									</Text>
								</MenuItem.Content>
							</MenuItem>
						);
					})}
				</MenuList>
			)}
		>
			<DropdownTriggerContext.Consumer>
				{({ triggerProps }) => (
					<button
						type="button"
						onClick={extendedToggle}
						aria-haspopup="true"
						aria-expanded={isOpen.toString()}
						{...triggerProps}
					>
						<Text color="primary" size="0.9rem">
							<Text ml={1} mr={2}>
								{displayText}
							</Text>
							{isOpen ? <CaretUp /> : <CaretDown />}
						</Text>
					</button>
				)}
			</DropdownTriggerContext.Consumer>
		</Dropdown>
	);
};

// #####################################################

const mapStateToProps = ({ global: { language } }) => ({
	language,
});

export default connect(mapStateToProps, null)(LangSelector);

// #####################################################
