export const ANY = "any";

export const GENDER = {
	WOMAN: 0,
	MAN: 1,
	COUPLE: 2,
	TRANSSEXUAL: 3,
};

export const GENDER_TEXT = {
	MAN: "man",
	WOMAN: "woman",
	COUPLE: "couple",
	TRANSSEXUAL: "transsexual",
};

export const AGE_RANGE = {
	MIN: 18,
	MAX: 70,
};

export const HEIGHT_RANGE = {
	MIN: 140,
	MAX: 220,
};

export const LATEST = "latest";

export const TOTAL = "total";

export const RANKING_PERIOD = {
	TODAY: "today",
	TOTAL: "total",
};

export const LANG = [
	{
		content: "CS (Český)",
		value: "cs",
	},
	{
		content: "DE (Deutsch)",
		value: "de",
	},
	{
		content: "EN (English)",
		value: "en",
	},
	{
		content: "PL (Polski)",
		value: "pl",
	},
	{
		content: "RU (Русский)",
		value: "ru",
	},
	{
		content: "UK (Українська)",
		value: "uk",
	},
];

export const LIMITS = {
	COMMENTS: {
		UNVERIFIED: 1,
		VIP: 30,
	},
	ANNOUNCEMENTS: 3,
};

export const VIP_STATUS = {
	UNVERIFIED: 1,
	VERIFIED: 2,
	VIP: 3,
	SUPER_VIP: 5,
};

export const LISTING_TYPE = {
	LIST: "list",
	GRID: "grid",
};

export const COUNTRIES_WITH_REGIONS = [
	"australia",
	"austria",
	"belarus",
	"belgium",
	"bosnia-and-herzegovina",
	"bulgaria",
	"canada",
	"croatia",
	"czech-republic",
	"denmark",
	"estonia",
	"finland",
	"france",
	"germany",
	"greece",
	"hungary",
	"iceland",
	"ireland",
	"italy",
	"latvia",
	"lithuania",
	"luxembourg",
	"netherlands",
	"norway",
	"poland",
	"portugal",
	"romania",
	"russia",
	"serbia",
	"slovakia",
	"slovenia",
	"spain",
	"sweden",
	"switzerland",
	"turkey",
	"ukraine",
	"united-kingdom",
	"united-states",
];

export const REGEX_PATTERN = {
	ONLY_LETTERS_AND_NUMBERS: /^[a-zA-Z0-9]+$/,
};

export const FILES_UPLOAD_RESTRICTIONS = {
	photo: {
		acceptTypes: ["image/jpeg", "image/png"],
		maxFileWeight: 26214400,
		minResolution: [600, 600],
	},
	video: {
		acceptTypes: [
			"video/mp4",
			"video/x-ms-wmv",
			"video/avi",
			"video/mpeg",
			"video/3gpp2",
			"video/quicktime",
		],
		maxFileWeight: 524288000,
	},
};
