import styled, { css } from "styled-components";

const List = styled("ul").attrs(() => ({
	role: "menu",
}))`
	flex: 1;
	list-style: none;
	margin: 0;
	padding: 0.35rem 0;

	${({ isSelector }) =>
		isSelector &&
		css`
			padding: 0.5rem 0rem;
		`}

	${({ isMenuList }) =>
		isMenuList &&
		css`
			padding: 0rem;
		`}

	${({ userpanelMenu }) =>
		userpanelMenu &&
		css`
			padding: 0.5rem 1rem;
		`}

	${({ userpanelSideMenu }) =>
		userpanelSideMenu &&
		css`
			padding: 0.25rem 0rem;
		`}

	${({ presentationTemplate }) =>
		presentationTemplate &&
		css`
			padding: 0.75rem 1rem;
		`}

	${({ customInput }) =>
		customInput &&
		css`
			padding-bottom: 0;
		`}
`;

export default List;
