import styled, { css } from "styled-components";
import Text from "components/typography/Text";

const Content = styled("div")`
	padding: 0.45rem 0.85rem;
	display: flex;
	${({ flexWrap }) =>
		flexWrap &&
		css`
			flex-flow: row wrap;
			line-height: 1.1;
		`}

	justify-content: ${({ space }) => (space ? "space-between" : "flex-start")};
	align-items: center;

	${({ $customPadding }) =>
		$customPadding &&
		css`
			padding: ${$customPadding};
		`}
`;

const Item = styled("li").attrs(() => ({
	role: "menuitem",
}))`
	background-color: transparent;
	user-select: none;

	${({ presentationTemplate }) =>
		presentationTemplate &&
		css`
			border: 1px solid
				${({ theme: { colors } }) =>
					colors.buttonsDefaultGrey}!important;
			box-shadow: ${({ theme: { utils } }) =>
				utils.createBoxShadow(["buttonWithText"])};
			border-radius: ${({ theme: { borderRadius } }) =>
				borderRadius.element};

			&:hover {
				background-color: ${({ theme: { colors } }) =>
					colors.buttonsDefaultGrey}!important;
			}

			${({ disabled }) =>
				disabled &&
				css`
					&:hover {
						background-color: transparent !important;
					}
				`}

			margin-bottom: 0.75rem;

			&:last-child {
				margin-bottom: 0;
			}
		`}

	${Content} {
		${({ presentationTemplate }) =>
			presentationTemplate &&
			css`
				height: 35px;
				font-size: 14px;
			`}
	}

	${({
		clickable,
		onClick,
		children: {
			props: { to },
		},
		allwaysSelected,
		alwaysLight,
		checked,
	}) =>
		(clickable || onClick || to) &&
		css`
			cursor: pointer;
			&:hover {
				background-color: ${({ theme: { colors } }) =>
					alwaysLight
						? colors.menuItemHoverAlwaysLight
						: colors.menuItemHover};
			}
			&:active {
				background-color: ${({ theme: { colors } }) =>
					alwaysLight
						? colors.menuItemActiveAlwaysLight
						: colors.menuItemActive};
			}

			${({ userpanelMenu }) =>
				userpanelMenu &&
				css`
					&:hover {
						background-color: transparent;
						color: ${({ theme: { colors, isDarkMode } }) =>
							isDarkMode
								? colors.primaryLight
								: "var(--dz-ref-color-primary400)"};
						a,
						a:visited,
						${Text} {
							color: ${({ theme: { colors, isDarkMode } }) =>
								isDarkMode
									? colors.primaryLight
									: "var(--dz-ref-color-primary400)"};
						}
					}
					&:active {
						font-weight: 700;
						background-color: transparent;
					}
				`}

			${allwaysSelected &&
			checked &&
			css`
				background-color: transparent;
			`}
		`}

	${({ alwaysLight }) =>
		alwaysLight
			? css`
					color: ${({ theme: { colorsDefaults } }) =>
						colorsDefaults.black};

					svg {
						color: ${({ theme: { colorsDefaults } }) =>
							colorsDefaults.primary};
					}
			  `
			: css`
					${({ userpanelMenu }) =>
						userpanelMenu &&
						css`
							&:active {
								background-color: transparent;
							}
							&.active {
								font-weight: 700;
								background-color: transparent;
								color: ${({ theme: { colors, isDarkMode } }) =>
									isDarkMode
										? colors.primaryLight
										: "var(--dz-ref-color-primary400)"};
								a,
								a:visited,
								${Text} {
									color: ${({
										theme: { colors, isDarkMode },
									}) =>
										isDarkMode
											? colors.primaryLight
											: "var(--dz-ref-color-primary400)"};
								}
								&:hover {
									background-color: transparent;
									color: ${({
										theme: { colors, isDarkMode },
									}) =>
										isDarkMode
											? colors.primaryLight
											: "var(--dz-ref-color-primary400)"};
									a,
									a:visited,
									${Text} {
										color: ${({
											theme: { colors, isDarkMode },
										}) =>
											isDarkMode
												? colors.primaryLight
												: "var(--dz-ref-color-primary400)"};
									}
								}
								&:active {
									background-color: transparent;
								}
							}
						`}
			  `}


	${({ disabled }) =>
		disabled &&
		css`
			opacity: 0.75;
			cursor: no-drop;
			&:hover {
				background-color: transparent;
			}
		`}

	a, a:visited {
		color: ${({ theme: { colors } }) => colors.black};
	}
`;

Item.Content = Content;

export default Item;
